import styled from '@emotion/styled';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Document } from '@contentful/rich-text-types';

import PrimaryButton, { ButtonVariant } from './buttons/PrimaryButton';
import Typography from './text/Typography';
import TextInput, { Validation, isValidationKey, validations } from './forms/TextInput';
import { breakpointQueries, colors, spacing } from '../../utils/styleguide';
import { hubSpotSubmit } from '../../utils/api/contentful';
import { prepareFormBody } from '../../utils/helpers/formHandler';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics';
import { useMediaQuery } from '../../utils/hooks/useMediaQuery';
import RichText from '../contentful-elements/RichText/RichText';
import { css } from '@emotion/react';

const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spacing[3]}px;
  padding-top: ${spacing[3]}px;
  align-items: end;
`;

const MessageWrapper = styled(Typography)`
  margin: ${spacing[8]}px 0;
`;

const Input = styled(TextInput)`
  margin: ${spacing[2]}px 0;
  width: 100%;
`;

const getInitialForm = (fields: FormFields[]): Record<string, string> => {
  return fields.reduce((acc: Record<string, string>, formItem): Record<string, string> => {
    acc[formItem.fieldName] = '';
    return acc;
  }, {});
};

const checkFormEmpty = (fields: FormFields[], updatedForm: Record<string, string>): boolean => {
  return fields.reduce((acc: boolean, { fieldName }): boolean => {
    return acc && !updatedForm[fieldName];
  }, true);
};

export type FormFields = {
  fieldName: string;
  type: string;
  placeholder?: string;
  required?: boolean;
};

type Props = {
  identifier: string;
  title?: string;
  description?: Document;
  submitUrl?: string;
  fields?: FormFields[];
  submitText?: string;
  formType?: string;
  successMessage?: string;
  errorMessage?: string;
  isAvailable: boolean;
  titleVariant?: 'overlineBold' | 'overline';
  buttonSize?: 'large' | 'small';
  buttonVariant?: ButtonVariant;
};

export default function HubSpotForm({
  identifier,
  title,
  description,
  submitText,
  submitUrl: url,
  fields,
  formType,
  successMessage,
  errorMessage,
  isAvailable,
  titleVariant = 'overlineBold',
  buttonSize = 'large',
  buttonVariant = 'solid',
}: Props) {
  const [isDesktopOrLarger] = useMediaQuery(breakpointQueries.desktop);

  const [formData, setFormData] = useState(getInitialForm(fields ?? []));
  const [formEmpty, setFormEmpty] = useState(true);
  const [message, setMessage] = useState<string | null>(null);
  const [requiredFieldsAreFilled, setRequiredFieldsAreFilled] = useState(false);
  const [anyErrors, setAnyErrors] = useState(false);

  const requiredFields = fields?.filter((field) => field.required).map((field) => field.fieldName);

  const contentForm = useMemo(() => {
    return fields?.map(({ fieldName, type, placeholder, required = false }, index) => {
      const validationsCheck: Validation[] =
        (isValidationKey(type) ? validations[type] : undefined) ||
        (type === 'text' && required ? validations['company'] : []);

      return (
        <Input
          key={index}
          label={placeholder + (required ? '*' : '')}
          type={type}
          name={fieldName}
          multiline={fieldName === 'message'}
          value={formData[fieldName]}
          required={required}
          css={{ margin: 0 }}
          validations={validationsCheck}
          setAnyErrors={setAnyErrors}
        />
      );
    });
  }, [formData, fields]);

  useEffect(() => {
    if (!url) {
      setMessage(
        'Contact form currently unavailable, please try again later or reach out to us at hello@milkywire.com.',
      );
    }
  }, [url]);

  const changeForm = (event: ChangeEvent<HTMLFormElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  async function hubspotSubmit() {
    if (!isAvailable || !url) {
      setMessage(
        'Contact form currently unavailable, please try again later or reach out to us at hello@milkywire.com.',
      );
      return;
    }

    const context: Record<string, unknown> = { pageName: identifier ?? '' };

    const submitMessage = await hubSpotSubmit({
      url,
      body: prepareFormBody({ type: formType, formData, ...context }),
      successMessage: successMessage ?? 'Thank you for keeping in touch!',
      errorMessage: errorMessage ?? 'Oops! Something went wrong, please try again later.',
    });
    setFormData(getInitialForm(fields ?? []));
    setMessage(submitMessage);

    // NOTE: "conversion" could from a usage perspective be seen as any attempted submission of the form.
    // However, to avoid having false positives (i.e. failed hubspot submissions possibly leading to more
    // than one "conversion" event if the user retries) we only consider the successful case
    if (submitMessage === successMessage) {
      sendEvent({ name: EVENT_NAMES.submitContactForm });
    }
  }

  useEffect(() => {
    const allRequiredFieldsFilled = requiredFields?.every(
      (field) => field && formData[field] !== '',
    );

    setRequiredFieldsAreFilled(!allRequiredFieldsFilled);
  }, [formData, requiredFields]);

  const onFormSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (formType === 'hubspot') {
      void hubspotSubmit();
    }
  };

  useEffect(() => {
    const isFormEmpty = checkFormEmpty(fields ?? [], formData);
    if (isFormEmpty !== formEmpty) {
      setFormEmpty(isFormEmpty);
    }
  }, [formData, formEmpty, setFormEmpty, fields]);

  if (!isAvailable) {
    return null;
  }

  return (
    <FormBlock>
      {title && (
        <Typography variant={titleVariant} color={colors.blackSecondary}>
          {title}
        </Typography>
      )}
      {description && (
        <RichText
          document={description}
          css={css`
            margin-top: ${title ? spacing[3] : 0}px;
          `}
        />
      )}
      {message && (
        <MessageWrapper color={colors.blackSecondary} variant="h6">
          {message}
        </MessageWrapper>
      )}
      {!message && (
        // Note: noValidate is used to disable default browser validation that shows a tooltip
        <Form noValidate onSubmit={onFormSubmit} onChange={changeForm}>
          {contentForm}
          <PrimaryButton
            disabled={formEmpty || requiredFieldsAreFilled || anyErrors}
            label={submitText ?? 'Submit'}
            tag="button"
            size={buttonSize}
            variant={buttonVariant}
            css={{
              marginTop: spacing[1],
              width: isDesktopOrLarger ? 'auto' : '100%',
            }}
          />
        </Form>
      )}
    </FormBlock>
  );
}
